// Third-party libraries
import React, { useEffect, useContext, useState } from "react";
import { Link as DomLink } from "react-router-dom";
import toast from "react-hot-toast";
import parse from "html-react-parser";
import InfoIcon from "@mui/icons-material/Info";
import ReactMarkdown from "react-markdown";
import {
  Select,
  Container,
  Typography,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Divider,
  Grid,
  Card,
  Stack,
  Backdrop,
  CircularProgress,
  Skeleton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  InputLabel,
} from "@mui/material";

// Local modules
import { CartContext } from "../../utils/stateHandlers/contexts";
import { api, storage, constants } from "../../utils";
import formatting from "../../utils/formatHelpers";
import { Mobile, Default } from "../../utils/deviceHelpers";
import gaFunctions from "../../utils/GAComponents/GAFunctions";

export default function Checkout(props) {
  const { cartState, cartDispatch } = useContext(CartContext);
  const {
    cart,
    fields,
    order,
    totals,
    submitted,
    shippingAddress,
    placedAt,
    pageLoaded,
    newAddress,
    newCreditCard,
    specialInstructions,
  } = cartState;
  const [openNewAddressModal, setOpenNewAddressModal] = useState(false);
  const [openNewCardModal, setOpenNewCardModal] = useState(false);
  const [showShippingPrice, setShowShippingPrice] = useState(true);
  const cartId =
    storage.getStorageItem("cartId") !== "null"
      ? storage.getStorageItem("cartId")
      : JSON.parse(storage.getStorageItem("cartId"));
  const currentUser = JSON.parse(storage.getStorageItem("currentUser"));

  const SelectField = (field) => {
    return (
      <Stack>
        <div>
          <Typography sx={{ fontWeight: "700", paddingBottom: ".3rem" }}>
            {field.label} *
            {field.name === "ShipTo" && (
              <span
                onClick={() => setOpenNewAddressModal(true)}
                className="header-link pointer"
                style={{ marginBottom: ".3rem", padding: "unset" }}
              >
                Add New Address
              </span>
            )}
            {field.name === "TermsCode" && (
              <span
                onClick={() => setOpenNewCardModal(true)}
                className="header-link pointer"
                style={{ marginBottom: ".3rem", padding: "unset" }}
              >
                Add New Credit Card
              </span>
            )}
          </Typography>
        </div>
        <Select
          key={field.name}
          defaultValue={field.default}
          size="small"
          onChange={(e) => {
            cartDispatch({
              type: "updateFields",
              field: field.name,
              payload: e.target.value,
            });
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                border: "1px solid #939598",
                borderRadius: "0",
              },
            },
          }}
        >
          {stripSelectLabels(field).map((option) => (
            <MenuItem value={option.value}>{option.label}</MenuItem>
          ))}
        </Select>
      </Stack>
    );
  };

  const InputField = (field) => (
    <Stack>
      <Typography
        sx={{
          fontWeight: "700",
          paddingBottom: ".3rem",
        }}
      >
        {field.label}
      </Typography>
      <TextField
        color="primary"
        onChange={(e) => {
          cartDispatch({
            type: "updateFields",
            field: field.name,
            payload: e.target.value,
          });
        }}
        size="small"
        sx={{
          width: "100%",
          margin: "unset",
        }}
      />
    </Stack>
  );

  const ShipViaField = (field) => (
    <FormControl>
      <FormLabel>
        <Typography
          sx={{ fontWeight: "700", paddingBottom: ".3rem", color: "black" }}
        >
          {field.label} *
        </Typography>
      </FormLabel>
      <RadioGroup
        value={order.ShipVia}
        sx={{ marginLeft: "2rem" }}
        onChange={(e) => {
          cartDispatch({
            type: "updateFields",
            field: field.name,
            payload: e.target.value,
          });
        }}
      >
        {field.values &&
          field.values.map((shipOption) => (
            <FormControlLabel
              key={shipOption.code}
              value={shipOption.code}
              control={<Radio />}
              label={
                <Typography>
                  {shipOption.desc} | {formatting["price2"](shipOption.amount)}
                </Typography>
              }
            />
          ))}
      </RadioGroup>
    </FormControl>
  );

  const SpecialInstructions = (field) => (
    <Stack>
      <Typography sx={{ fontWeight: "700", paddingBottom: ".3rem" }}>
        Special Instructions
      </Typography>
      <TextField
        color="primary"
        variant="outlined"
        multiline
        fullWidth
        rows={3}
        sx={{
          margin: "unset",
        }}
        onChange={(e) => {
          cartDispatch({
            type: "setSpecialInstructions",
            payload: e.target.value,
          });
        }}
      />
    </Stack>
  );

  function stripSelectLabels(field) {
    return field.values.map((option) => {
      option.label = option.label.replace(" <br />", " | ");
      option.label = option.label.replace(" <br />", " | ");
      return option;
    });
  }

  function checkout() {
    cartDispatch({ type: "setPageLoaded", payload: false });
    const shippingAddressField = fields.find(
      (field) => field.name === "ShipTo"
    );

    const selectedAddress = shippingAddressField.values.find(
      (address) => address.value === order.ShipTo
    );
    cartDispatch({
      type: "setShippingAddress",
      payload: selectedAddress.label,
    });
    const orderInfo = {
      cart_id: cartId,
      MultipleOk: order.MultipleOk,
      Notes: specialInstructions,
      PoNumber: order.PoNumber,
      ShipTo: order.ShipTo,
      ShipVia: order.ShipVia,
      TermsCode: order.TermsCode,
    };
    api
      .post("orders/submit", orderInfo)
      .then((response) => {
        if (response.isAxiosError) {
          const errorMessage = [];
          response.data.errors.forEach(function (error) {
            errorMessage.push(error);
          });
          toast.error(
            `There was an error processing your request. ${errorMessage.toString()}`
          );
        } else {
          cartDispatch({
            type: "setOrderSubmitted",
            payload: {
              placedAt: response.data.PlacedAt,
              submitted: true,
            },
          });
          cartDispatch({ type: "setPageLoaded", payload: true });
          cartDispatch({
            type: "setCart",
            payload: [],
          });
          cartDispatch({ type: "setCartCount", payload: 0 });
          const gaPurchaseInfo = {
            items: cart,
            transaction_id: cartId,
            tax: totals.tax.amount,
            shipping: totals.trans,
            total: totals.total,
          };
          gaFunctions.gaPurchase(gaPurchaseInfo);
        }
      })
      .catch((error) => {
        toast.error(`There was an error processing your request. ${error}`);
      });
  }

  function sortFields(fields) {
    const sortedFields = fields.map((field) => ({
      ...field,
      SortOrder:
        field.name === "ShipTo"
          ? 1
          : field.name === "ShipVia"
          ? 2
          : field.name === "TermsCode"
          ? 3
          : field.name === "MultipleOk"
          ? 4
          : field.name === "PoNumber"
          ? 5
          : 6,
    }));
    return sortedFields.sort((a, b) => a.SortOrder - b.SortOrder);
  }

  function updateFields(newFields) {
    const oldFields = fields.filter((field) =>
      ["MultipleOk", "PoNumber", "Notes"].includes(field.name)
    );
    const updatedFields = [oldFields];
    newFields.forEach(function (field) {
      if (field.name === "ShipTo") {
        updatedFields.push(field);
      } else if (field.name === "ShipVia") {
        updatedFields.push(field);
      } else if (field.name === "TermsCode") {
        updatedFields.push(field);
      } else return;
    });
    const flattenedFields = [].concat(...updatedFields);
    const sortedFields = sortFields(flattenedFields);
    return sortedFields;
  }

  function reloadFields() {
    setShowShippingPrice(false);
    const queryString = `orders/form?cart_id=${cartId}&ShipTo=${order.ShipTo}&TermsCode=${order.TermsCode}&ShipVia=${order.ShipVia}`;
    api.fetch(queryString).then((response) => {
      if (response.isAxiosError) {
      } else {
        const updatedFields = updateFields(response.data.fields);
        cartDispatch({
          type: "reloadFields",
          payload: {
            updated_fields: updatedFields,
            totals: response.data.totals,
          },
        });
        setShowShippingPrice(true);
      }
    });
  }

  function submitNewAddress() {
    const newAddressInfo = {
      customer_key: currentUser.CustomerKey,
      name: newAddress.name,
      street_1: newAddress.street_1,
      street_2: newAddress.street_2,
      city: newAddress.city,
      state: newAddress.state,
      zip_code: newAddress.zip_code,
      country_code: "US",
    };
    api.post("orders/addAddress", newAddressInfo).then((response) => {
      if (response.isAxiosError) {
        const errorMessage = [];
        response.data.errors.forEach(function (error) {
          errorMessage.push(error);
        });
        toast.error(
          `There was an error processing your request. ${errorMessage.toString()}`
        );
      } else {
        cartDispatch({
          type: "updateFields",
          field: "ShipTo",
          payload: response.data.ShipToId,
        });
        setOpenNewAddressModal(false);
      }
    });
  }

  function submitNewCard() {
    const newCardInfo = {
      Name: newCreditCard.Name,
      Number: newCreditCard.Number,
      ExpirationMonth: newCreditCard.ExpirationMonth,
      ExpirationYear: newCreditCard.ExpirationYear,
      Cvv: newCreditCard.Cvv,
    };
    api.post("orders/addCard", newCardInfo).then((response) => {
      if (response.isAxiosError) {
        const errorMessage = [];
        response.data.errors.forEach(function (error) {
          errorMessage.push(error);
        });
        toast.error(
          `There was an error processing your request. ${errorMessage.toString()}`
        );
      } else {
        cartDispatch({
          type: "updateFields",
          field: "TermsCode",
          payload: response.data.CardId,
        });
        setOpenNewCardModal(false);
      }
    });
  }

  useEffect(() => {
    cartDispatch({ type: "setPageLoaded", payload: false });
    cartDispatch({
      type: "setCheckout",
      payload: {
        fields: [],
        order: null,
        totals: null,
      },
    });
    cartDispatch({
      type: "setOrderSubmitted",
      payload: {
        placedAt: "",
      },
    });
    api.fetch("orders/form?cart_id=", cartId).then((response) => {
      if (response.isAxiosError) {
        toast.error(
          "There was an error processing your request. Please make sure that you are logged in and have items in your cart."
        );
      } else {
        cartDispatch({
          type: "setCheckout",
          payload: {
            fields: sortFields(response.data.fields),
            order: response.data.order,
            totals: response.data.totals,
          },
        });
        cartDispatch({ type: "setPageLoaded", payload: true });
        const gaCheckoutInfo = {
          items: cart,
          value: response.data.totals.total,
        };
        gaFunctions.gaBeginCheckout(gaCheckoutInfo);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      reloadFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <CartContext.Provider value={{ cartState, cartDispatch }}>
      {!pageLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="xl">
          <Mobile>
            <Stack spacing={2}>
              <Grid item xs={6}>
                <Card
                  variant="outlined"
                  sx={{
                    marginTop: "1.6rem",
                    padding: "2rem",
                  }}
                >
                  {!submitted ? (
                    <Stack spacing={2}>
                      <Typography variant="h1" sx={{ margin: "unset" }}>
                        Place Your Order
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        align="center"
                        sx={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                      ></Typography>
                      {fields &&
                        fields.map((field) => (
                          <React.Fragment key={field.name}>
                            {field.name === "ShipTo"
                              ? SelectField(field)
                              : field.name === "ShipVia"
                              ? ShipViaField(field)
                              : field.name === "MultipleOk"
                              ? SelectField(field)
                              : field.name === "TermsCode"
                              ? SelectField(field)
                              : field.name === "PoNumber"
                              ? InputField(field)
                              : field.name === "Notes"
                              ? SpecialInstructions(field)
                              : ""}
                          </React.Fragment>
                        ))}
                      <Typography variant="subtitle2">* = required</Typography>
                    </Stack>
                  ) : (
                    <Stack spacing={3}>
                      <Typography variant="h1" sx={{ margin: "unset" }}>
                        Your Order Has Been Submitted!
                      </Typography>
                      <Typography>
                        Please check your email for confirmation.
                      </Typography>
                      <Typography>Order Placed At: {placedAt}</Typography>
                      <Typography>
                        Billing To: {currentUser.BillTo.Street1} |{" "}
                        {currentUser.BillTo.Street2} | {currentUser.BillTo.City}
                        , {currentUser.BillTo.State}{" "}
                        {currentUser.BillTo.ZipCode}
                      </Typography>
                      <Typography>Shipping To: {shippingAddress}</Typography>
                    </Stack>
                  )}
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  variant="outlined"
                  sx={{ marginTop: "1.6rem", padding: "2rem" }}
                >
                  <Stack spacing={2}>
                    <Typography variant="h1" sx={{ margin: "unset" }}>
                      Order Details
                    </Typography>
                    <Typography
                      variant="greenRectangle"
                      align="center"
                      sx={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                    >
                      <InfoIcon /> Prices and weights shown are estimates and
                      subject to change. Most product is sold by weight and
                      billed at daily market prices. Orders will be priced at
                      the metal markets on the date of receipt, if order is
                      shipped in the same day; or the markets of the day after
                      the order is received for out of stock product. Market
                      price is the second London fix plus a surcharge.
                    </Typography>
                    <Typography
                      variant="articleSubTitle"
                      align="center"
                      sx={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                    >
                      <ReactMarkdown linkTarget="_blank">
                        If you currently pay by Credit Card, you will now see a
                        2.9% Credit Card fee on your invoice. If you would like
                        to avoid this fee, [**CLICK
                        HERE**](https://api2.hooverandstrong.com/pdfs/CreditApplication-2-7-24-Fillable.pdf)
                        to fill out a Net 30 account credit application.
                      </ReactMarkdown>
                    </Typography>
                    <Stack spacing={3}>
                      {totals && (
                        <>
                          {cart.map((lineItem) => (
                            <React.Fragment key={lineItem.id}>
                              {lineItem.invoices.map((invoice) => (
                                <Stack key={invoice.specification}>
                                  <Typography
                                    variant="articleText"
                                    sx={{ marginBottom: "1rem" }}
                                  >
                                    <strong>
                                      {parse(invoice.specification)}
                                    </strong>{" "}
                                    |{" "}
                                    {formatting["removeSpace"](invoice.weight)}{" "}
                                    | {formatting["price2"](invoice.total)}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    Estimated Ship Date:{" "}
                                    {lineItem.ship_info.ship_date}
                                  </Typography>
                                </Stack>
                              ))}
                            </React.Fragment>
                          ))}

                          <Divider flexItem />
                          <Typography
                            variant="articleText"
                            sx={{ marginBottom: "1rem" }}
                          >
                            Subtotal: {formatting["price2"](totals.subtotal)}
                          </Typography>
                          <Typography
                            variant="articleText"
                            sx={{ marginBottom: "1rem" }}
                          >
                            Shipping:
                            {showShippingPrice ? (
                              formatting["price2"](totals.trans)
                            ) : (
                              <Skeleton
                                variant="text"
                                width="10rem"
                                className="float-right"
                              />
                            )}
                          </Typography>
                          <Typography
                            variant="articleText"
                            sx={{ marginBottom: "1rem" }}
                          >
                            Sales Tax:
                            {showShippingPrice ? (
                              formatting["price2"](
                                totals.tax && totals.tax.amount
                              )
                            ) : (
                              <Skeleton
                                variant="text"
                                width="10rem"
                                className="float-right"
                              />
                            )}
                          </Typography>
                          <Typography
                            variant="articleText"
                            sx={{ marginBottom: "1rem" }}
                          >
                            <strong>
                              Total:{" "}
                              {showShippingPrice ? (
                                formatting["price2"](totals.total)
                              ) : (
                                <Skeleton
                                  variant="text"
                                  width="10rem"
                                  className="float-right"
                                />
                              )}
                            </strong>
                          </Typography>
                        </>
                      )}
                    </Stack>
                    {!submitted && (
                      <Stack
                        direction="row"
                        spacing={3}
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        sx={{ marginTop: "2rem" }}
                      >
                        <Button
                          size="small"
                          color="success"
                          variant="contained"
                          onClick={() => checkout()}
                        >
                          Place Order
                        </Button>
                        <Button
                          size="small"
                          color="secondary"
                          variant="contained"
                        >
                          <DomLink to={{ pathname: "/cart" }}>Cancel</DomLink>
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                </Card>
              </Grid>
            </Stack>
          </Mobile>
          <Default>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Card
                  variant="outlined"
                  sx={{
                    marginTop: "1.6rem",
                    padding: "3rem",
                    minHeight: submitted ? "" : "100rem",
                  }}
                >
                  {!submitted ? (
                    <Stack spacing={2}>
                      <Typography variant="h1" sx={{ margin: "unset" }}>
                        Place Your Order
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        align="center"
                        sx={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                      ></Typography>
                      {fields &&
                        fields.map((field) => (
                          <React.Fragment key={field.name}>
                            {field.name === "ShipTo"
                              ? SelectField(field)
                              : field.name === "ShipVia"
                              ? ShipViaField(field)
                              : field.name === "MultipleOk"
                              ? SelectField(field)
                              : field.name === "TermsCode"
                              ? SelectField(field)
                              : field.name === "PoNumber"
                              ? InputField(field)
                              : field.name === "Notes"
                              ? SpecialInstructions(field)
                              : ""}
                          </React.Fragment>
                        ))}
                      <Typography variant="subtitle2">* = required</Typography>
                    </Stack>
                  ) : (
                    <Stack spacing={3}>
                      <Typography variant="h1" sx={{ margin: "unset" }}>
                        Your Order Has Been Submitted!
                      </Typography>
                      <Typography>
                        Please check your email for confirmation.
                      </Typography>
                      <Typography>Order Placed At: {placedAt}</Typography>
                      <Typography>
                        Billing To: {currentUser.BillTo.Street1} |{" "}
                        {currentUser.BillTo.Street2} | {currentUser.BillTo.City}
                        , {currentUser.BillTo.State}{" "}
                        {currentUser.BillTo.ZipCode}
                      </Typography>
                      <Typography>Shipping To: {shippingAddress}</Typography>
                    </Stack>
                  )}
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  variant="outlined"
                  sx={{ marginTop: "1.6rem", padding: "3rem" }}
                >
                  <Stack spacing={2}>
                    <Typography variant="h1" sx={{ margin: "unset" }}>
                      Order Details
                    </Typography>
                    <Typography
                      variant="greenRectangle"
                      align="center"
                      sx={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                    >
                      <InfoIcon /> Prices and weights shown are estimates and
                      subject to change. Most product is sold by weight and
                      billed at daily market prices. Orders will be priced at
                      the metal markets on the date of receipt, if order is
                      shipped in the same day; or the markets of the day after
                      the order is received for out of stock product. Market
                      price is the second London fix plus a surcharge.
                    </Typography>
                    <Typography
                      variant="articleSubTitle"
                      align="center"
                      sx={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                    >
                      <ReactMarkdown linkTarget="_blank">
                        If you currently pay by Credit Card, you will now see a
                        2.9% Credit Card fee on your invoice. If you would like
                        to avoid this fee, [**CLICK
                        HERE**](https://api2.hooverandstrong.com/pdfs/CreditApplication-2-7-24-Fillable.pdf)
                        to fill out a Net 30 account credit application.
                      </ReactMarkdown>
                    </Typography>
                    <Stack
                      spacing={3}
                      justifyContent="flex-end"
                      alignItems="flex-end"
                    >
                      {totals && (
                        <>
                          {cart.map((lineItem) => (
                            <React.Fragment key={lineItem.id}>
                              {lineItem.invoices.map((invoice) => (
                                <Stack
                                  key={invoice.specification}
                                  justifyContent="flex-end"
                                  alignItems="flex-end"
                                >
                                  <Typography
                                    variant="articleText"
                                    sx={{ marginBottom: "1rem" }}
                                  >
                                    {parse(invoice.specification)} |{" "}
                                    {formatting["removeSpace"](invoice.weight)}{" "}
                                    | {formatting["price2"](invoice.total)}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    Estimated Ship Date:{" "}
                                    {lineItem.ship_info.ship_date}
                                  </Typography>
                                </Stack>
                              ))}
                            </React.Fragment>
                          ))}

                          <Divider flexItem />
                          <Typography
                            variant="articleText"
                            sx={{ marginBottom: "1rem" }}
                          >
                            Subtotal: {formatting["price2"](totals.subtotal)}
                          </Typography>
                          <Typography
                            variant="articleText"
                            sx={{ marginBottom: "1rem" }}
                          >
                            Shipping:{" "}
                            {showShippingPrice ? (
                              formatting["price2"](totals.trans)
                            ) : (
                              <Skeleton
                                variant="text"
                                width="10rem"
                                className="float-right"
                              />
                            )}
                          </Typography>
                          <Typography
                            variant="articleText"
                            sx={{ marginBottom: "1rem" }}
                          >
                            Sales Tax:{" "}
                            {showShippingPrice ? (
                              formatting["price2"](
                                totals.tax && totals.tax.amount
                              )
                            ) : (
                              <Skeleton
                                variant="text"
                                width="10rem"
                                className="float-right"
                              />
                            )}
                          </Typography>
                          <Typography
                            variant="articleText"
                            sx={{ marginBottom: "1rem" }}
                          >
                            Total:{" "}
                            {showShippingPrice ? (
                              formatting["price2"](totals.total)
                            ) : (
                              <Skeleton
                                variant="text"
                                width="10rem"
                                className="float-right"
                              />
                            )}
                          </Typography>
                        </>
                      )}
                    </Stack>
                    {!submitted && (
                      <Stack
                        direction="row"
                        spacing={3}
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        sx={{ marginTop: "2rem" }}
                      >
                        <DomLink to={{ pathname: "/cart" }}>
                          <Button
                            size="small"
                            color="secondary"
                            variant="contained"
                          >
                            Cancel
                          </Button>
                        </DomLink>
                        <Button
                          size="small"
                          color="success"
                          variant="contained"
                          onClick={() => checkout()}
                        >
                          Place Order
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Default>
          <Dialog
            open={openNewAddressModal}
            onClose={() => setOpenNewAddressModal(false)}
            fullWidth
            scroll="body"
          >
            <>
              <DialogTitle sx={{ textTransform: "lowercase" }}>
                New Shipping Address
              </DialogTitle>
              <DialogContent sx={{ minHeight: "40rem" }} dividers={true}>
                <Stack spacing={1}>
                  <Typography sx={{ marginBottom: "2rem" }}>
                    Fill out the fields to add a new address.
                  </Typography>
                  <InputLabel>Name *</InputLabel>
                  <TextField
                    color="primary"
                    onChange={(e) =>
                      cartDispatch({
                        type: "setNewAddress",
                        field: "name",
                        payload: e.target.value,
                      })
                    }
                    value={newAddress.name}
                    size="small"
                    sx={{
                      width: "100%",
                      margin: "unset",
                    }}
                  />
                  <InputLabel>Street 1 *</InputLabel>
                  <TextField
                    color="primary"
                    onChange={(e) =>
                      cartDispatch({
                        type: "setNewAddress",
                        field: "street_1",
                        payload: e.target.value,
                      })
                    }
                    value={newAddress.street_1}
                    size="small"
                    sx={{
                      width: "100%",
                      margin: "unset",
                    }}
                  />
                  <InputLabel>Street 2</InputLabel>
                  <TextField
                    color="primary"
                    onChange={(e) =>
                      cartDispatch({
                        type: "setNewAddress",
                        field: "street_2",
                        payload: e.target.value,
                      })
                    }
                    value={newAddress.street_2}
                    size="small"
                    sx={{
                      width: "100%",
                      margin: "unset",
                    }}
                  />
                  <InputLabel>City *</InputLabel>
                  <TextField
                    color="primary"
                    onChange={(e) =>
                      cartDispatch({
                        type: "setNewAddress",
                        field: "city",
                        payload: e.target.value,
                      })
                    }
                    value={newAddress.city}
                    size="small"
                    sx={{
                      width: "100%",
                      margin: "unset",
                    }}
                  />
                  <Grid container direction="row">
                    <Grid item xs={5}>
                      <InputLabel>State *</InputLabel>
                      <Select
                        value={newAddress.state}
                        size="small"
                        onChange={(e) => {
                          cartDispatch({
                            type: "setNewAddress",
                            field: "state",
                            payload: e.target.value,
                          });
                        }}
                        sx={{ width: "100%" }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              border: "1px solid #939598",
                              borderRadius: "0",
                            },
                          },
                        }}
                      >
                        {constants.statesOptions.map((option) => (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={6}>
                      <InputLabel>Zip Code *</InputLabel>
                      <TextField
                        color="primary"
                        onChange={(e) =>
                          cartDispatch({
                            type: "setNewAddress",
                            field: "zip_code",
                            payload: e.target.value,
                          })
                        }
                        value={newAddress.zip_code}
                        size="small"
                        sx={{
                          width: "100%",
                          margin: "unset",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </DialogContent>
              <DialogActions>
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpenNewAddressModal(false)}
                    size="small"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => submitNewAddress()}
                    variant="contained"
                    color="success"
                    size="small"
                  >
                    Add
                  </Button>
                </Stack>
              </DialogActions>
            </>
          </Dialog>
          <Dialog
            open={openNewCardModal}
            onClose={() => setOpenNewCardModal(false)}
            fullWidth
            scroll="body"
          >
            <>
              <DialogTitle sx={{ textTransform: "lowercase" }}>
                New Credit Card
              </DialogTitle>
              <DialogContent dividers={true}>
                <Stack spacing={1}>
                  <Typography sx={{ marginBottom: "2rem" }}>
                    Fill out the fields to add a new credit card.
                  </Typography>
                  <InputLabel>Name *</InputLabel>
                  <TextField
                    color="primary"
                    onChange={(e) =>
                      cartDispatch({
                        type: "setNewCreditCard",
                        field: "Name",
                        payload: e.target.value,
                      })
                    }
                    value={newCreditCard.Name}
                    size="small"
                    sx={{
                      width: "100%",
                      margin: "unset",
                    }}
                  />
                  <InputLabel>Card Number *</InputLabel>
                  <TextField
                    color="primary"
                    onChange={(e) =>
                      cartDispatch({
                        type: "setNewCreditCard",
                        field: "Number",
                        payload: e.target.value,
                      })
                    }
                    value={newCreditCard.Number}
                    size="small"
                    sx={{
                      width: "100%",
                      margin: "unset",
                    }}
                  />
                  <Grid container direction="row">
                    <Grid item xs={5}>
                      <InputLabel>Exp Month (MM) *</InputLabel>
                      <TextField
                        color="primary"
                        onChange={(e) =>
                          cartDispatch({
                            type: "setNewCreditCard",
                            field: "ExpirationMonth",
                            payload: e.target.value,
                          })
                        }
                        value={newCreditCard.ExpirationMonth}
                        size="small"
                        sx={{
                          width: "100%",
                          margin: "unset",
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={6}>
                      <InputLabel>Exp Year (YYYY) *</InputLabel>
                      <TextField
                        color="primary"
                        onChange={(e) =>
                          cartDispatch({
                            type: "setNewCreditCard",
                            field: "ExpirationYear",
                            payload: e.target.value,
                          })
                        }
                        value={newCreditCard.ExpirationYear}
                        size="small"
                        sx={{
                          width: "100%",
                          margin: "unset",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={2}>
                      <InputLabel>CVV *</InputLabel>
                      <TextField
                        color="primary"
                        onChange={(e) =>
                          cartDispatch({
                            type: "setNewCreditCard",
                            field: "Cvv",
                            payload: e.target.value,
                          })
                        }
                        value={newCreditCard.Cvv}
                        size="small"
                        sx={{
                          width: "100%",
                          margin: "unset",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </DialogContent>
              <DialogActions>
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpenNewCardModal(false)}
                    size="small"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => submitNewCard()}
                    variant="contained"
                    color="success"
                    size="small"
                  >
                    Add
                  </Button>
                </Stack>
              </DialogActions>
            </>
          </Dialog>
        </Container>
      )}
    </CartContext.Provider>
  );
}
